<script setup lang="ts">
import { computed } from "vue"
import StreamControlsAddFunction from "@/components/StreamControlsAddFunction.vue"
import StreamControlsFilter from "@/components/StreamControlsFilter.vue"
import StreamControlsFunctionEditor from "@/components/StreamControlsFunctionEditor.vue"
import StreamControlsInsertSpacer from "@/components/StreamControlsInsertSpacer.vue"
import StreamControlsSpacer from "@/components/StreamControlsSpacer.vue"
import { destinationImagePath } from "@/common/destination"
import { getLatestMetricInstance, getMeasurement } from "@/common/metrics"
import { sourceImagePath } from "@/common/source"
import { numAbbreviation, smartDecimal } from "@/utils/number"

import type {
  Destination,
  Filter,
  ListPosition,
  MetricInstance,
  Source,
  Stream,
  StreamConfig,
  StreamFunction,
} from "@/types/types"

interface Props {
  stream: Stream | null | undefined
  config: StreamConfig | undefined
  destination: Destination | undefined
  source: Source | undefined
  sourceMetrics: MetricInstance[]
  writesActive: boolean
}

const props = withDefaults(defineProps<Props>(), {})
const emit = defineEmits(["update"])

const { $toast } = useNuxtApp()

const addFunctionIndex = ref<number | undefined>()
const functionsAdded = ref(0) // added since page loaded

const functions = computed(() => props.config?.functions || [])
const functionCount = computed(() => functions.value.length)
const sourceImage = computed(() => sourceImagePath(props.source))
const destinationImage = computed(() => destinationImagePath(props.destination))

// metrics
const sourceEvents = computed(() => {
  const instance = getLatestMetricInstance(props.sourceMetrics)
  const events = getMeasurement(instance, "events")
  return events ?? 0 // return 0 events if they dont exist
})

const showStatusTag = computed(
  () => props.source?.mode && ["test", "paused"].includes(props.source.mode)
)

const showSourceEvents = computed(() => props.source?.mode !== "paused")

const sourceEventsClass = computed(() =>
  props.source?.mode === "test" ? "text-blue-300" : "text-slate-400"
)

function addFunction(newFunction: StreamFunction, index?: number) {
  const functions = [...(props.config?.functions || [])]
  if (index !== undefined) {
    functions.splice(index, 0, newFunction) // insert between existing
  } else {
    functions.push(newFunction) // add to end
  }
  _updateConfig({
    functions,
    successMsg: "Function added",
    errorMsg: "Function could not be added",
  })?.then(() => {
    addFunctionIndex.value = undefined
    functionsAdded.value += 1
  })
}

function cancelInsert() {
  addFunctionIndex.value = undefined
}

function deleteFunction(functionId: string) {
  if (!props.config?.functions.length) return
  const index = props.config.functions.findIndex((f) => f.id === functionId)
  if (index === -1) return
  const functions = [...props.config.functions]
  functions.splice(index, 1)
  _updateConfig({
    functions,
    successMsg: "Function deleted",
    errorMsg: "Function could not be deleted",
  })
}

function moveDown(id: string) {
  const funcIds = functions.value.map((f) => f.id)
  const index = funcIds.indexOf(id)
  if (index < 0) return // not found
  if (index >= funcIds.length - 1) return // already last element

  const funcs = [...props.config!.functions]
  const [func] = funcs.splice(index, 1)
  funcs.splice(index + 1, 0, func)

  _updateConfig({
    functions: funcs,
    successMsg: "Function updated",
    errorMsg: "Function could not be updated",
  })
}

function moveUp(id: string) {
  const funcIds = functions.value.map((f) => f.id)
  const index = funcIds.indexOf(id)
  if (index < 0) return // not found
  if (index === 0) return // already first element

  const funcs = [...props.config!.functions]
  const [func] = funcs.splice(index, 1)
  funcs.splice(index - 1, 0, func)

  _updateConfig({
    functions: funcs,
    successMsg: "Function updated",
    errorMsg: "Function could not be updated",
  })
}

function removeFilter() {
  _updateConfig({
    filter: {},
    successMsg: "Stream filter removed",
    errorMsg: "Unable to update filter",
  })
}

function formatMetricValue(value: number) {
  const [num, unit] = numAbbreviation(value)
  return `${smartDecimal(num, 3)}${unit}`
}

function insertAbove(index: number) {
  addFunctionIndex.value = index
}

function listPosition(func: StreamFunction): ListPosition {
  const funcIds = functions.value.map((f) => f.id)
  const index = funcIds.indexOf(func.id)
  return { position: index, count: functions.value.length }
}

function updateFilter(filter: Filter) {
  _updateConfig({
    filter,
    successMsg: "Stream filter set",
    errorMsg: "Unable to update filter",
  })
}

function updateFunction(func: StreamFunction) {
  if (!props.config?.functions.length) return
  const index = props.config.functions.findIndex((f) => f.id === func.id)
  if (index === -1) return
  const functions = [...props.config.functions]
  functions[index] = func
  _updateConfig({
    functions,
    successMsg: "Function updated",
    errorMsg: "Function could not be updated",
  })
}

function displayNode(index: number) {
  // if we are inserting a function, temporarily increment node number of functions below insert
  if (addFunctionIndex.value === undefined) return index
  if (addFunctionIndex.value > index) return index
  return index + 1
}

function modeColor(source: Source) {
  if (source.mode === "active") return "emerald"
  if (source.mode === "paused") return "amber"
  if (source.mode === "test") return "blue"
  return undefined
}

interface updateArgs {
  functions?: StreamFunction[]
  filter?: Filter
  successMsg?: string
  errorMsg?: string
}

function _updateConfig({
  functions,
  filter,
  successMsg = "Stream updated",
  errorMsg = "Stream could not be updated",
}: updateArgs) {
  if (!props.stream) return
  functions ||= props.config?.functions || []
  filter ||= props.config?.filter || {}

  return $api<StreamConfig>(`/streams/${props.stream.id}/configs`, {
    method: "POST",
    body: { filter, functions },
  })
    .then((result) => {
      $toast.success(successMsg)
      emit("update", result)
    })
    .catch(() => $toast.error(errorMsg))
}
</script>

<template>
  <div v-if="stream && destination && source" class="wrapper w-full">
    <div class="border border-slate-200 bg-white flex shadow-01 rounded">
      <div class="p-4 border-r border-slate-100">
        <img class="h-8 w-8" src="/images/streams/source.svg" alt="" />
      </div>
      <div class="grow py-2 px-4 flex items-center space-x-4">
        <div class="flex items-center flex-grow">
          <img class="h-10 w-10 rounded" :src="sourceImage" alt="" />
          <div class="mt-1.5 ml-3">
            <h4 class="text-xs text-slate-400 font-semibold">Source</h4>
            <p>
              <NuxtLink
                :to="`/sources/${source.id}`"
                class="text-indigo-500 font-medium"
              >
                {{ props.source?.name }}
              </NuxtLink>
            </p>
          </div>
        </div>
        <div v-if="showSourceEvents" :class="sourceEventsClass">
          <span class="text-xl">{{ formatMetricValue(sourceEvents) }}</span>
          <span class="text-xs ml-1 mr-2">events/s</span>
        </div>
        <div v-if="showStatusTag" class="flex items-center bg-pink-50">
          <VTag :color="modeColor(source)">{{ source.mode }}</VTag>
        </div>
      </div>
      <div class="p-4 border-l border-slate-100">
        <NuxtLink :to="`/sources/${source.id}`">
          <VButton color="secondary">View Details</VButton>
        </NuxtLink>
      </div>
    </div>

    <StreamControlsSpacer />

    <StreamControlsFilter
      :filter="config?.filter"
      @remove="removeFilter"
      @update="updateFilter"
    />

    <template v-for="(func, index) in functions" :key="func.id">
      <StreamControlsInsertSpacer
        :index="index"
        :active-index="addFunctionIndex"
        :add-count="functionsAdded"
        @add="addFunction"
        @insert="insertAbove(index)"
        @cancel="cancelInsert"
      />

      <StreamControlsFunctionEditor
        :function="func"
        :list-position="listPosition(func)"
        :node="displayNode(index)"
        @delete="deleteFunction"
        @move-down="moveDown"
        @move-up="moveUp"
        @update="updateFunction"
      />
    </template>

    <StreamControlsSpacer />

    <StreamControlsAddFunction
      :add-count="functionsAdded"
      :disabled="!!addFunctionIndex"
      :node="functionCount"
      @add="addFunction"
    />

    <StreamControlsSpacer :state="writesActive ? undefined : 'disabled'" />

    <div class="border border-slate-200 bg-white flex shadow-01 rounded">
      <div class="p-4 border-r border-slate-100">
        <img class="h-8 w-8" src="/images/streams/destination.svg" alt="" />
      </div>
      <div class="grow px-4 flex items-center">
        <img class="h-10 w-10 rounded" :src="destinationImage" alt="" />
        <div class="mt-1.5 ml-3 grow">
          <h4 class="text-xs text-slate-400 font-semibold">Destination</h4>
          <p>
            <NuxtLink
              :to="`/destinations/${destination.id}`"
              class="text-indigo-500 font-medium"
            >
              {{ destination.name }}
            </NuxtLink>
          </p>
        </div>
        <VTag v-if="!writesActive" color="amber">Writes Disabled</VTag>
      </div>
      <div class="p-4 border-l border-slate-100">
        <NuxtLink :to="`/destinations/${destination?.id}`">
          <VButton color="secondary">View Details</VButton>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
