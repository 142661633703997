<script setup lang="ts">
import { Menu, MenuButton, MenuItems } from "@headlessui/vue"
import DotsVerticalIcon from "@/assets/icons/dots-vertical.svg?component"

import type { ListPosition, StreamFunction } from "@/types/types"

interface Props {
  function: StreamFunction
  listPosition: ListPosition
}

const props = withDefaults(defineProps<Props>(), {})
const emits = defineEmits(["disable", "enable", "moveDown", "moveUp"])

const disabled = computed(() => !!props.function.disabled)

const isFirst = computed(() => props.listPosition.position === 0)
const isLast = computed(
  () => props.listPosition.position === props.listPosition.count - 1
)
const isSolo = computed(() => props.listPosition.count === 1)
</script>

<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="flex items-center rounded bg-white text-gray-600 px-1 py-1 border border-slate-200 hover:text-gray-800 focus:outline-none"
      >
        <span class="sr-only">Open options</span>
        <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-20 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <VMenuItem v-show="!disabled" @click="emits('disable')">
            Disable
          </VMenuItem>
          <VMenuItem v-show="disabled" @click="emits('enable')">
            Enable
          </VMenuItem>

          <!-- start: sorting -->
          <template v-if="!isSolo">
            <div class="my-1 h-px bg-gray-100" />

            <VMenuItem :disabled="isFirst" @click="emits('moveUp')">
              Move ↑
            </VMenuItem>
            <VMenuItem :disabled="isLast" @click="emits('moveDown')">
              Move ↓
            </VMenuItem>
          </template>
          <!-- end: sorting -->

          <VMenuItem v-if="false"> Delete Function </VMenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
