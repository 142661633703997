<script setup lang="ts">
// editor for existing functions

import StreamControlsFunctionMenu from "@/components/StreamControlsFunctionMenu.vue"
import ChevronDown from "@/assets/icons/chevron-down.svg?component"
import ChevronRight from "@/assets/icons/chevron-right.svg?component"
import FunctionEditor from "@/components/FunctionEditor.vue"
import { FUNCTIONS } from "@/common/function"
import { clone, omit } from "@/utils/object"

import type { ListPosition, StreamFunction } from "@/types/types"

interface Props {
  function: StreamFunction
  listPosition: ListPosition
  node: number
}

const props = withDefaults(defineProps<Props>(), {})
const emits = defineEmits(["delete", "moveDown", "moveUp", "update"])

const editedFunction = reactive<StreamFunction>({
  id: "",
  type: "drop", // specify default to satisfy type requirements
  label: "",
  filter: {},
  config: {},
})

const isEditing = ref(false)

// make public methods on child component available via ref
const functionEditor = ref<InstanceType<typeof FunctionEditor> | null>(null)

const currentFunction = computed(() =>
  FUNCTIONS.find((f) => f.id === props.function.type)
)

const disabled = computed(() => !!props.function.disabled)

const functionName = computed(() => currentFunction.value?.name)

const functionNameClasses = computed(() =>
  disabled.value ? "opacity-30 line-through" : ""
)

const functionImage = computed(() => {
  let integration: string | undefined

  if (currentFunction.value?.integration) {
    if (currentFunction.value.integration === "code") {
      return undefined // dont render an icon for code
    } else if (currentFunction.value.integration === "datadog") {
      integration = "datadog_agent"
    } else {
      integration = currentFunction.value.integration.toLowerCase()
    }
  }

  return integration ? `/images/sources/${integration}.svg` : undefined
})

const wrapperClasses = computed(() => {
  const classes: string[] = []
  if (isEditing.value) classes.push("overflow-hidden")
  // reduce emphasis if disabled
  classes.push(disabled.value ? "bg-slate-25" : "shadow-01")
  return classes
})

watch(
  () => props.function,
  (newFunction) => _updateLocalState(newFunction),
  { immediate: true, deep: true }
)

function cancel() {
  _updateLocalState(props.function) // reset to persisted
  isEditing.value = false
}

function disableFunction() {
  const updated = clone(props.function)
  updated.disabled = true
  emits("update", updated)
}

function enableFunction() {
  const updated = omit(props.function, "disabled")
  emits("update", updated)
}

function moveDown() {
  emits("moveDown", props.function.id)
}

function moveUp() {
  emits("moveUp", props.function.id)
}

function remove() {
  emits("delete", props.function.id)
}

function toggleEdit() {
  isEditing.value = !isEditing.value
}

async function saveEdits() {
  if (functionEditor.value) {
    // check field validations
    const valid = await functionEditor.value.validate()
    if (!valid) return
  }

  emits("update", clone(editedFunction))
  isEditing.value = false
}

function updateFunction(func: StreamFunction) {
  editedFunction.filter = func.filter
  editedFunction.config = func.config
}

function _updateLocalState(newFunction: StreamFunction) {
  editedFunction.id = newFunction.id
  editedFunction.type = newFunction.type
  editedFunction.label = newFunction.label
  editedFunction.filter = structuredClone(toRaw(newFunction.filter))
  editedFunction.config = structuredClone(toRaw(newFunction.config))
}
</script>

<template>
  <div class="border border-slate-200 rounded" :class="wrapperClasses">
    <div class="bg-white flex justify-between">
      <div class="flex grow">
        <div class="p-4 border-r border-slate-100">
          <VPoint :disabled="disabled">{{ props.node + 1 }}</VPoint>
        </div>
        <div
          class="grow py-4 pl-3 flex items-center space-x-3 cursor-pointer select-none"
          @click="toggleEdit"
        >
          <div>
            <ChevronRight v-if="!isEditing" class="text-slate-600 h-6 w-6" />
            <ChevronDown v-else class="text-indigo-600 h-6 w-6" />
          </div>

          <img
            v-if="functionImage"
            :src="functionImage"
            alt="Integration"
            class="h-7 w-7 rounded"
          />

          <div
            class="text-slate-600 leading-none py-2 font-medium"
            :class="functionNameClasses"
          >
            {{ functionName }}
          </div>
        </div>
      </div>
      <div class="flex justify-end items-center space-x-2 p-4">
        <VInput
          v-show="isEditing"
          v-model="editedFunction.label"
          placeholder="Optional label for function..."
          class="w-96"
        />
        <div v-show="!isEditing" class="flex items-center">
          <span class="text-slate-400 px-4" :class="{ 'opacity-30': disabled }">
            {{ editedFunction.label }}
          </span>
          <StreamControlsFunctionMenu
            :function="props.function"
            :list-position="listPosition"
            @disable="disableFunction"
            @enable="enableFunction"
            @move-down="moveDown"
            @move-up="moveUp"
          />
        </div>
      </div>
    </div>
    <div v-show="isEditing">
      <FunctionEditor
        v-if="isEditing"
        ref="functionEditor"
        :stream-function="editedFunction"
        @update="updateFunction"
      />
      <VButtonBar>
        <VButton color="secondary" @click="cancel">Cancel</VButton>
        <VButton color="positive" @click="saveEdits">Save</VButton>
        <template #left>
          <VButton color="negative" @click="remove"> Delete Function </VButton>
        </template>
      </VButtonBar>
    </div>
  </div>
</template>
