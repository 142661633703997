import type { StreamFunction, StreamFunctionType } from "@/types/types"

export interface UIFunctionConfig {
  id: StreamFunctionType
  name: string
  description: string
  filterRequired?: boolean
  noConfig?: boolean
  integration?: string
}

export const FUNCTIONS: UIFunctionConfig[] = [
  {
    id: "convert_to_otlp_preview",
    name: "Convert to OTLP",
    description: "Convert events to OTLP format.",
    integration: "open_telemetry",
  },
  {
    id: "drop",
    name: "Drop",
    description: "Drops events with matching conditions.",
    filterRequired: true,
    noConfig: true,
  },
  {
    id: "extract",
    name: "Extract",
    description: "Extract data from a specified field into new fields.",
  },
  {
    id: "parse",
    name: "Parse",
    description: "Extract structured data from common log formats.",
  },
  {
    id: "remove",
    name: "Remove",
    description: "Remove specified field.",
  },
  { id: "rename", name: "Rename", description: "Rename specified field." },
  {
    id: "replace",
    name: "Replace",
    description:
      "Replace a string field utilizing capture groups and templates.",
  },
  {
    id: "set",
    name: "Set",
    description: "Set the value of a specified field.",
  },
  {
    id: "truncate",
    name: "Truncate",
    description: "Truncate the length of fields by bytes or characters.",
  },
  {
    id: "dd_metric_to_dist",
    name: "Drop Tags from Metrics",
    description:
      "Drop tags and create new distribution metrics from Datadog metrics.",
    integration: "datadog",
  },
  {
    id: "dd_drop_metric",
    name: "Drop Metrics",
    description: "Drop Datadog metrics by name or pattern.",
    integration: "datadog",
  },
  {
    id: "dd_unroll_metric",
    name: "Unroll Metrics",
    description: "Unroll batched Datadog metrics into standard events.",
    integration: "datadog",
  },
  {
    id: "otlp_drop_log",
    name: "Drop logs",
    description: "Drop OpenTelemetry logs",
    integration: "open_telemetry",
  },
  {
    id: "bloblang",
    name: "Bloblang Code",
    description: "Utilize Bloblang to transform an event",
    integration: "code",
  },
  {
    id: "javascript",
    name: "Javascript Code",
    description: "Utilize Javascript to transform an event",
    integration: "code",
  },
]

// function types that are visible for all users of the site. other functions
// may be limited to use via feature flags.
export const RELEASED_FUNCTIONS: StreamFunctionType[] = [
  "bloblang",
  "javascript",
  "drop",
  "extract",
  "parse",
  "remove",
  "rename",
  "replace",
  "set",
  "truncate",
  "dd_metric_to_dist",
  "dd_drop_metric",
  "dd_unroll_metric",
  "otlp_drop_log",
]

type StreamFunctionConfigMap = {
  [T in StreamFunctionType]: Extract<StreamFunction, { type: T }>["config"]
}

// default config state for known function types
export const DEFAULT_CONFIGS: StreamFunctionConfigMap = {
  bloblang: { bloblang_operations: [{ mapping: "" }] },
  convert_to_otlp_preview: {
    convert_to_otlp_operations: [{ body_target: "" }],
  },
  drop: {},
  extract: {
    extract_operations: [
      {
        target: "",
        expression: "",
        format: "goregex",
        destination: "",
        overwrite_existing: true,
      },
    ],
  },
  javascript: { javascript_operations: [{ script: "" }] },
  keep: { keep_operations: [{ target: "" }] },
  parse: { parse_operations: [{ field_name: "", format: "json" }] },
  remove: { remove_operations: [{ target: "" }] },
  rename: { rename_operations: [{ target: "", value: "" }] },
  replace: {
    replace_operations: [
      {
        field_name: "",
        match_pattern: "",
        template: "",
        excluded_fields: [],
      },
    ],
  },
  set: { set_operations: [{ target: "", value: "" }] },
  truncate: {
    truncate_operations: [{ limit: 0, limit_by: "bytes", field_names: [] }],
  },
  dd_drop_metric: {
    dd_drop_metric_operations: [{ metrics: [] }],
  },
  dd_metric_to_dist: {
    dd_metric_to_dist_operations: [
      { metrics: [], tags: [], drop_original_metric: true },
    ],
  },
  dd_unroll_metric: {
    dd_unroll_metric_operations: [{ metrics: [] }],
  },
  otlp_drop_log: {
    otlp_drop_log_operations: [
      {
        match_conditions: {
          basic_expression: {
            conditions: [{ selector: "", operator: "==", value: "" }],
            match_all: false,
          },
        },
      },
    ],
  },
}
