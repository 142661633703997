<script setup lang="ts">
// A dialog for confirming dangerous actions. Page will be masked
// while user agrees (or disagrees) to continue.

import BaseModal from "@/components/BaseModal.vue"

interface Props {
  display: boolean // is the dialog currently showing?
  title: string
  confirmText?: string // customize button text
}

const props = withDefaults(defineProps<Props>(), {
  confirmText: "Confirm",
})
const emits = defineEmits(["confirm", "cancel"])

function cancel() {
  emits("cancel")
}

function confirm() {
  emits("confirm")
}
</script>

<template>
  <BaseModal
    :display="display"
    position="center"
    size="medium"
    width="1/3"
    @close="cancel"
  >
    <div class="px-5 pt-6 pb-5">
      <div>{{ props.title }}</div>
      <div class="text-sm text-slate-400 py-6 space-y-2">
        <slot></slot>
      </div>
      <div class="flex justify-center gap-2">
        <VButton color="secondary" @click="cancel">Cancel</VButton>
        <VButton color="danger" @click="confirm">{{ confirmText }}</VButton>
      </div>
    </div>
  </BaseModal>
</template>
