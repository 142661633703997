<script setup lang="ts">
import { useForm } from "vee-validate"
import { useConfirmDialog } from "@vueuse/core"
import { UPDATE_STREAM } from "@/form_schemas/stream"

import type { Stream, StreamUpdate } from "@/types/types"

interface Props {
  stream: Stream | null
}

const props = withDefaults(defineProps<Props>(), {})
const emits = defineEmits(["archive", "update"])

const deleteTitle = computed(() =>
  props.stream ? `Delete "${props.stream.name}"?` : ""
)

const { handleSubmit, resetForm } = useForm({
  validationSchema: UPDATE_STREAM,
  initialValues: {
    name: "",
  },
})

watch(
  () => props.stream?.name,
  (newName) => {
    if (newName) resetForm({ values: { name: newName } })
  },
  { immediate: true }
)

const { isRevealed, reveal, cancel } = useConfirmDialog()

const updateStream = handleSubmit((vals) => {
  const update: StreamUpdate = { name: vals.name }
  emits("update", update)
})

function archiveStream() {
  emits("archive")
}
</script>

<template>
  <VSection>
    <VFormInput name="name" label="Name" />
    <template #footer>
      <div class="flex justify-between space-x-2">
        <VButton color="negative" @click="reveal">Delete Stream</VButton>
        <VButton color="positive" @click="updateStream">Save</VButton>
      </div>
    </template>

    <VConfirmDialoog
      :display="isRevealed"
      :title="deleteTitle"
      confirm-text="Delete Stream"
      @confirm="archiveStream"
      @cancel="cancel"
    >
      <p>Your stream will stop processing and be permanently removed.</p>
    </VConfirmDialoog>
  </VSection>
</template>
