import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M10.2 13.8a1.8 1.8 0 1 0 0 3.6 1.8 1.8 0 0 0 0-3.6m0-5.4a1.8 1.8 0 1 0 0 3.6 1.8 1.8 0 0 0 0-3.6M8.4 4.8a1.8 1.8 0 1 1 3.6 0 1.8 1.8 0 0 1-3.6 0"
    }, null, -1)
  ])))
}
export default { render: render }