<script setup lang="ts">
// clickable items for using inside a dropdown menu

// NOTE: For best accessibility results & browser experience, menu items that cause
// an action should be rendered as button and items that navigate the user should
// be rendered as a link.
//
// However HeadlessUI's MenuItem and NuxtLink don't seem play well together. Using
// a raw <a> tag will cause the client-side to reload which we don't want. For now
// use a @click handler with navTo if navigation is needed.
// More details: https://github.com/tailwindlabs/headlessui/issues/2243

import { MenuItem } from "@headlessui/vue"

interface Props {
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
})

const emits = defineEmits(["click"])
</script>

<template>
  <MenuItem v-slot="{ active }" :disabled="props.disabled">
    <button
      :class="[
        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
        disabled ? 'opacity-30' : '',
      ]"
      class="block w-full text-left px-4 py-2 text-sm"
      @click="emits('click')"
    >
      <slot></slot>
    </button>
  </MenuItem>
</template>
